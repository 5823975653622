import { ApiError } from '@/@core/models/api/errors';
import { Response } from '@aleph-alpha/lib-http';
import { HTTPError, KyResponse } from 'ky';

// TODO: adapt types after moving to ky only
export async function handleRequestError<R, T extends KyResponse<R> | Response<R>>(
  makeRequestCallback: () => Promise<T>
): Promise<T> {
  try {
    return await makeRequestCallback();
  } catch (error: unknown) {
    if (error instanceof HTTPError) {
      throw new ApiError(error.message, { cause: error.cause });
    } else if (
      error &&
      typeof error === 'object' &&
      'constructor' in error &&
      error.constructor.name === 'HTTPError' &&
      'message' in error &&
      typeof error.message === 'string'
    ) {
      // Error is an instance of HTTPError, but not of the correct class. This is a workaround that may be removed when
      // upstream contains a solution for this issue.
      throw new ApiError(error.message);
    }
    throw new ApiError();
  }
}
