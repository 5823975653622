import { z } from 'zod';

const DOCUMENTS_RESPONSE_SCHEMA = z.object({
  name: z.string(),
  created: z.string(),
  version: z.number(),
});

const METADATA = z.object({
  filename: z.string().optional(),
  size: z.number().optional(),
});

export const DOCUMENT_RESPONSE_SCHEMA = z.object({
  name: z.string(),
  metadata: METADATA.optional(),
});

export const COLLECTION_RESPONSE_SCHEMA = z.object({
  collection: z.string(),
  documents: z.array(DOCUMENTS_RESPONSE_SCHEMA).optional(),
});

export const PROVIDED_COLLECTION_RESPONSE_SCHEMA = z.array(COLLECTION_RESPONSE_SCHEMA);

export const UPLOAD_DOCUMENT_RESPONSE = z.object({
  namespace: z.string(),
  collection_name: z.string(),
  document_name: z.string(),
});

export type CollectionResponse = z.infer<typeof COLLECTION_RESPONSE_SCHEMA>;
export type DocumentResponse = z.infer<typeof DOCUMENT_RESPONSE_SCHEMA>;
export type UploadDocumentResponse = z.infer<typeof UPLOAD_DOCUMENT_RESPONSE>;
export type ProvidedCollectionResponse = z.infer<typeof PROVIDED_COLLECTION_RESPONSE_SCHEMA>;
