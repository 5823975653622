import { LocalizedError, mapErrorsToUserFriendlyErrors } from '@/@core/utils';
import { PiniaPluginContext } from 'pinia';

type UseNotificationStore = () => {
  addErrorNotification: (errorMessage: string) => void;
};

type Locale = {
  locale: {
    value: string;
  };
};

// @ts-expect-error TODO legacy code does not pass type checks
const showNotification = (error: any, useNotificationStore: UseNotificationStore): boolean => {
  const notificationStore = useNotificationStore();
  if (error instanceof LocalizedError) {
    notificationStore.addErrorNotification(error.message);
    return true;
  }
};

const showNotificationAsync = async (
  error: any,
  useNotificationStore: UseNotificationStore,
  locale: Locale
  // @ts-expect-error TODO legacy code does not pass type checks
): Promise<boolean> => {
  const notificationStore = useNotificationStore();
  if (error.name === 'LocalizedError') {
    notificationStore.addErrorNotification(error.message);
    return true;
  }

  const errorMessage = await mapErrorsToUserFriendlyErrors(error.message, locale.locale.value);
  notificationStore.addErrorNotification(errorMessage);
};

const asyncActionWrapper =
  (originalAction: any, useNotificationStore: UseNotificationStore, locale: Locale) =>
  async (...args: any[]) => {
    try {
      return await originalAction(...args);
    } catch (error) {
      const shouldStopErrorPropagation = await showNotificationAsync(
        error,
        useNotificationStore,
        locale
      );
      if (shouldStopErrorPropagation) return;
      throw error;
    }
  };

const actionWrapper =
  (originalAction: any, useNotificationStore: UseNotificationStore) =>
  (...args: any[]) => {
    try {
      return originalAction(...args);
    } catch (error) {
      const shouldStopErrorPropagation = showNotification(error, useNotificationStore);
      if (shouldStopErrorPropagation) return;
      throw error;
    }
  };

export const piniaPluginErrorNotifier =
  (useNotificationStore: UseNotificationStore, locale: any) =>
  ({ store, options: { actions } }: PiniaPluginContext) => {
    const wrappedActions = {};
    for (const key of Object.keys(actions)) {
      const isAsync = actions[key].constructor.name === 'AsyncFunction';
      const originalAction = store[key].bind(store);
      if (isAsync) {
        wrappedActions[key] = asyncActionWrapper(originalAction, useNotificationStore, locale);
      } else {
        wrappedActions[key] = actionWrapper(originalAction, useNotificationStore);
      }
    }
    return wrappedActions;
  };
