import { AvailableGenerationLengths } from '@/modules/generation/utils';
import { z } from 'zod';

export interface GenerationRequest {
  prompt: string;
  keywords: string[];
  tone_of_voice: string;
  output_length: AvailableGenerationLengths;
}

export const GENERATION_RESPONSE_SCHEMA = z.object({
  text: z.string(),
});

export type GenerationResponse = z.infer<typeof GENERATION_RESPONSE_SCHEMA>;
