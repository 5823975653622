import { FeatureFlagClient } from './FeatureFlagClient';
import { FeatureFlag } from '@/@core/models/FeatureFlags';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';

/**
 * The client instance for managing feature flags.
 */
const featureFlagClient = new FeatureFlagClient({});

/**
 * Retrieves the value of a feature flag based on the given key.
 */
export const useFeatureFlag = (flagKey: FeatureFlag, defaultValue: boolean = false): boolean => {
  return featureFlagClient.getFlag(flagKey, defaultValue);
};

/**
 * Initializes the feature flag client with the given configuration.
 */
export const initFeatureFlag = async (): Promise<FeatureFlagClient> => {
  const config = await ASSISTANT_SERVICE.featureFlags();
  featureFlagClient.setFlags(config);
  return featureFlagClient;
};

export const enableInDevelopmentFeatures = () => {
  return useFeatureFlag('enableInDevelopmentFeatures') && useFeatureFlag('enableBetaFeatures');
};
